<template>
  <div class="auth">
    <div class="content">
      <div class="auth__row">
        <RegisterSteps
          class="full-width"
          :title="
            $route.params.exist ? 'Ваш аккаунт уже зарегистрирован' : 'Вход'
          "
          :description="'Используйте свой email или номер телефона для входа'"
        />
        <form class="auth__form" action="javascript:void(0)">
          <div class="register-step">
            <div class="header-auth">
              <div class="header-auth__items">
                <div
                  class="header-auth__item"
                  @click="tab = 1"
                  :class="{ 'header-auth__item_active': tab === 1 }"
                >
                  Телефон
                </div>
                <div
                  class="header-auth__item"
                  @click="tab = 2"
                  :class="{ 'header-auth__item_active': tab === 2 }"
                >
                  Email
                </div>
              </div>
              <div class="header-auth__tab" v-if="tab === 1">
                <div v-if="!codeSend">
                  <TextField
                    :type="'tel'"
                    :maskString="'+7 (###) ###-##-##'"
                    :placeholder="'+7(---) --- -- --'"
                    v-model="phoneNumber.value"
                    :isError="phoneNumber.isError"
                    :errorText="phoneNumber.errorText"
                  ></TextField>
                  <div
                    class="header-auth__get-sms button_pink button"
                    @click="getSmsCode"
                  >
                    Получить код
                  </div>
                </div>
                <div v-else-if="!codeHelp">
                  <div class="header-auth__description">
                    Отправили смс с кодом на номер +7 {{ phoneNumber.value }}.
                    <span
                      class="header-auth__description_pink"
                      @click="changePhone"
                      >Изменить номер?</span
                    >
                  </div>
                  <TextField
                    :type="'tel'"
                    v-model="smsCode.value"
                    :maskString="'####'"
                    :label="'Код из смс*'"
                    :isError="smsCode.isError"
                    :errorText="smsCode.errorText"
                    :successState="smsCode.success"
                    class="mb-4"
                  >
                    <GetCode
                      :refresh="authPhoneResend"
                      class="ml-4 ml-xs-0 mt-xs-4"
                    />
                  </TextField>

                  <div class="header-auth__code-help" @click="codeHelp = true">
                    Не пришел код
                  </div>
                </div>
                <div v-else>
                  <div class="header-auth__help-caption">Что делать</div>
                  <ol class="header-auth__help-list">
                    <li class="header-auth__help-list-item">
                      Возможно, вы ввели не тот номер или ошиблись в цифре.
                      Проверьте всё ещё раз.
                    </li>
                    <li class="header-auth__help-list-item">
                      Проблема может быть со стороны вашего оператора сотовой
                      связи. Обратитесь в службу поддержки.
                    </li>
                  </ol>
                  <div
                    class="header-auth__back button button_empty-pink"
                    @click="codeHelp = false"
                  >
                    Назад
                  </div>
                </div>
              </div>
              <div class="header-auth__tab" v-if="tab === 2">
                <TextField
                  :type="'email'"
                  v-model="email.value"
                  :placeholder="'E-mail'"
                  :isError="email.isError"
                  :errorText="email.errorText"
                  class="mb-3"
                />
                <TextField
                  :type="showPass ? 'text' : 'password'"
                  v-model="password.value"
                  :placeholder="'Пароль'"
                  :isError="password.isError"
                  :errorText="password.errorText"
                  class="pass-block"
                />
                <div class="header-auth__buttons">
                  <div
                    class="header-auth__login button_pink button"
                    @click="login"
                  >
                    Войти
                  </div>
                  <router-link
                    :to="{ name: 'RestorePassword', query: $route.query }"
                    class="header-auth__forgot-pass"
                    >Забыли пароль?</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <RegisterFooter>
            <div class="register-block">
              <div class="register-block__title">
                Если у вас нет учетной записи:
              </div>
              <router-link
                class="register-block__button button button_empty-pink"
                :to="{ name: 'Register', query: $route.query }"
                >Зарегистрироваться</router-link
              >
            </div>
            <div class="header-auth__sso mt-8 pt-8 mb-8">
              <a
                href="https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token"
                class="button_gray button"
              >
                Вход сотрудникам AZ
              </a>
            </div>
          </RegisterFooter>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextField from "@/components/form-elements/TextField.vue";
import GetCode from "@/components/form-elements/getCode.vue";
import RegisterSteps from "../../components/auth/RegisterSteps.vue";
import RegisterFooter from "../../components/auth/RegisterFooter.vue";

export default {
  metaInfo: {
    title: "Авторизация",
  },
  components: { TextField, GetCode, RegisterSteps, RegisterFooter },
  name: "Login",
  data: () => ({
    tab: 1,
    phoneNumber: {
      value: "",
      isError: false,
      errorText: "",
    },
    codeSend: false,
    smsCode: {
      value: "",
      isError: false,
      errorText: "",
      success: false,
    },
    codeHelp: false,
    email: {
      value: "",
      isError: false,
      errorText: "",
    },
    password: {
      value: "",
      isError: false,
      errorText: "",
    },
    showPass: "",
  }),
  computed: {
    ...mapGetters(["Rules"]),
    authData() {
      return {
        username: this.email.value.toLowerCase(),
        password: this.password.value,
      };
    },
  },
  methods: {
    ...mapActions(["auth", "authPhone", "authPhoneResend", "authPhoneCode"]),
    async getSmsCode() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("authPhone");
      let error = false;
      if (!this.Rules.isRequired(this.phoneNumber.value)) {
        error = true;
        this.phoneNumber.isError = true;
        this.phoneNumber.errorText = "Некорректный номер";
      }
      if (!this.Rules.isCorrectPhone(this.phoneNumber.value)) {
        error = true;
        this.phoneNumber.isError = true;
        this.phoneNumber.errorText = "Некорректный номер";
      }
      if (!error) {
        if (
          await this.authPhone({
            phone: this.phoneNumber.value,
            captcha: token,
          })
        ) {
          this.codeSend = true;
        }
      }
    },
    changePhone() {
      this.codeSend = false;
      this.smsCode.value = "";
      this.smsCode.success = false;
    },
    async login() {
      if (!this.Rules.isRequired(this.email.value)) {
        this.email.isError = true;
        this.email.errorText = "Нужно заполнить Email адрес";
        return;
      }
      if (!this.Rules.isCorrectEmail(this.email.value)) {
        this.email.isError = true;
        this.email.errorText = "Некорректный Email";
        return;
      }
      if (!this.Rules.isRequired(this.password.value)) {
        this.password.isError = true;
        this.password.errorText = "Введите пароль";
        return;
      }
      let auth = await this.auth(this.authData)
      if (auth) {
        let url;
        let add_query = { ...this.$route.query };
        delete add_query.next_url;
        delete add_query.source;
        try {
          url = new URL(this.$route.query.next_url || this.$route.query.source);
        } catch {
          url = new URL(
            "https://" +
              (this.$route.query.next_url || this.$route.query.source)
          );
        }
        url.search += new URLSearchParams(auth).toString();
        url.search += "&" + new URLSearchParams(add_query).toString();
        window.open(url, "_self");
        return;
      }
    },
  },
  mounted() {
    if (this.$route?.query?.email) {
      this.email = this.$route.query.email;
    }
    if (this.$route && this.$route.params && this.$route.params.phone) {
      this.phoneNumber.value = this.$route.params.phone;
    }
    if (this.$route && this.$route.params && this.$route.params.email) {
      this.email.value = this.$route.params.email;
    }
  },
  watch: {
    "phoneNumber.value": function () {
      this.phoneNumber.isError = false;
      this.phoneNumber.errorText = "";
    },
    "email.value": function () {
      this.email.isError = false;
      this.email.errorText = "";
    },
    "password.value": function () {
      this.password.isError = false;
      this.password.errorText = "";
    },
    "smsCode.value": async function () {
      if (this.smsCode.value.length >= 4) {
        let checkPhoneCode = await this.authPhoneCode(this.smsCode.value);
        if (checkPhoneCode) {
          let url;
          let add_query = { ...this.$route.query };
          delete add_query.next_url;
          delete add_query.source;
          try {
            url = new URL(
              this.$route.query.next_url || this.$route.query.source
            );
          } catch {
            url = new URL(
              "https://" +
                (this.$route.query.next_url || this.$route.query.source)
            );
          }
          url.search += new URLSearchParams(checkPhoneCode).toString();
          url.search += "&" + new URLSearchParams(add_query).toString();
          window.open(url, "_self");
          return;
        } else {
          this.smsCode.isError = true;
          this.smsCode.success = false;
          this.smsCode.errorText = "Неправильный код";
          return;
        }
      }
      this.smsCode.isError = false;
      this.smsCode.errorText = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  @media screen and (max-width: 1220px) {
    width: 100%;
  }
}
.auth {
  height: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    padding: 40px 0;
    height: auto;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__form {
    width: 595px;
    margin: 0 auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}

.register-block {
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
  }

  &__button {
    width: 217px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.auth-step {
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
}

.header-auth {
  width: 384px;
  @media screen and (max-width: 1220px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 32px;
  }
  &__tab {
    width: 100%;
  }

  &__get-sms {
    width: 170px;
    height: 40px;
    margin-top: 16px;
  }

  &__login {
    width: 100%;
    height: 40px;
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #424753;
  }

  &__items {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
  }

  &__item {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #b2b4b4;
    padding-bottom: 8px;
    cursor: pointer;

    &_active {
      color: #3c4242;
      border-bottom: 2px solid #d0006f;
    }
  }

  &__description {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }

  &__code-help {
    margin-top: 6px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &__help-caption {
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #424753;
  }

  &__help-list {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #666666;
    padding-left: 15px;
    list-style-type: number;
  }

  &__help-list-item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__back {
    margin-top: 16px;
    padding: 4px 16px;
    display: inline-flex;
  }

  &__forgot-pass {
    margin-left: 16px;
    flex-shrink: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #d0006f;
    }

    @media screen and (max-width: 767px) {
      margin-top: 16px;
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 24px;
    }
  }
}
</style>

<style lang="scss">
.auth .get-code__refresh-code {
  width: 100%;
}
.header .header-auth {
  &__sso {
    border-top: 1px solid #ebefee;

    & .button {
      width: 217px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>